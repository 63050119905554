import React from 'react';
import { ReactComponent as Dot } from '../../../assets/icons/system/carousel_step_icon.svg';
import { ReactComponent as DotActive } from '../../../assets/icons/system/carousel_step_icon_active.svg';

export default React.memo(function ArrowControls({ goTo, getCurrentIndex, totalCount, className = '' }) {

  const onDotClick = (e) => {
    goTo(e.currentTarget.dataset.index);
  };

  return (
    <div className={`${className}`}>
      <ul
        className={`grid grid-cols-[repeat(auto-fit,_34px)] auto-rows-fr ${totalCount > 15 ? 'gap-1' : 'gap-4'} items-center justify-center place-content-center`}>
        {Array.from({ length: totalCount }, (v, i) =>
          <li key={i}>
            <button className={'rounded-full overflow-hidden'} onClick={onDotClick} data-index={i}>
              {getCurrentIndex() === i ? <DotActive /> : <Dot />}
            </button>
          </li>)}
      </ul>
    </div>
  );
})

import React from 'react';
import ImageBlock from '../imageBlock';
import GameDescriptionBlock from '../gameDescBlock';
import BadgesGrid from '../gameBadgesGrid';
import Overview from '../overview';
import GameFeatures from '../gameFeatures';
import SimilarGames from '../infoBlocks/similarGamesBlock';
import Carousel from '../../uiKit/carousel';
import ArrowsOverControls from '../../uiKit/carousel/controls/arrowsOverControlsWithDotsIndicator';
import DotControls from '../../uiKit/carousel/controls/dotControls';
import { useParams, Navigate } from 'react-router-dom';
import { useGetGameQuery } from '../../redux/api/games';
import { parseGameBundleFromUrlParam } from '../../utils/transformGameTitleToUrl';
import { usePageTitle } from '../../utils/hooks/usePageTitle';
import { useMetaTags } from '../../utils/hooks/useMetaTags';
import { useBreakpoint } from '../../utils/hooks/useBreakpoint';
import MobileScreenshotWrapper from '../../uiKit/MobileScreenshotWrapper';
import GameReviews from '../gameReviews';

export default React.memo(function GamePage({ data }) {
  const params = useParams();
  const { key: breakpointKey } = useBreakpoint();
  let { data: game, isFetching } = useGetGameQuery(parseGameBundleFromUrlParam(params.bundle), { skip: !!data });
  game = data ?? game;

  usePageTitle(game?.name);
  useMetaTags([
    { name: 'description', content: game?.overview || '' },
    { property: 'og:title', content: game?.name || '' },
    { property: 'og:description', content: game?.overview.slice(0, 150) + '...' || '' },
    { property: 'og:image', content: game?.imagePreview || '' },
    { name: 'twitter:title', content: game?.name || '' },
    { name: 'twitter:description', content: game?.overview.slice(0, 150) + '...' || '' },
    { name: 'twitter:image', content: game?.imagePreview || '' },

  ]);

  let slidesPerView;
  const isVertical = game?.screenshotsView === 'vertical';
  if (game) {
    const isSmallScreen = ['xs', 'sm'].includes(breakpointKey);
    slidesPerView = isVertical && !isSmallScreen ? 3 : 1;
  }

  return isFetching ? null : !game ? <Navigate to={'/404'} replace={true} /> : (
    <>

      <ImageBlock src={game.imagePreview} mobileSrc={game.imagePreviewMobile}
                  childWrapperClassName={'!items-end !pb-0'}>
        <div className={'w-full h-full flex flex-col items-center md:pt-24'}>
          <div className={'w-full h-full min-h-0 flex items-end justify-center md:w-11/12 lg:w-9/12 md:pb-10'}>
            <GameDescriptionBlock game={game} />
          </div>
        </div>
      </ImageBlock>

      <div className={'w-full py-20 px-4 md:px-12 xl:px-44'}>
        <BadgesGrid game={game} />
      </div>

      <div className={'w-full md:py-24 px-4 md:px-12 xl:px-44'}>
        <Overview text={game.overview} />
      </div>

      {game.featuresInfo && !!game.featuresInfo.length &&
        <div className={'w-full pt-20 md:py-28 px-4 md:px-12 xl:px-44'}>
          <GameFeatures features={game.featuresInfo} />
        </div>}

      {game.gameplayScreenshots &&
        <div className={'w-full pt-40 pb-44 md:py-28 px-4 md:px-12 xl:px-44 relative bg-white'}>
          <Carousel slidesPerView={slidesPerView} loop={5000}
                    className={`${slidesPerView === 1 ? '' : 'w-10/12 m-auto'} rounded-3xl`} applyControls={props => <>
            <ArrowsOverControls {...props} className={'hidden md:flex'} itemClassName={'bottom-6'} />
            <DotControls {...props} className={'md:hidden mt-8'} />
          </>}>
            {game.gameplayScreenshots.map(src => {
                return !isVertical ?
                  <div key={src} className={`w-full h-full ${slidesPerView === 1 ? '' : 'px-6'}`}>
                    <img src={src} alt={`${game.name} gameplay`}
                         className={`object-cover w-full h-full ${slidesPerView === 1 ? '' : 'rounded-3xl'}`}
                         draggable={false} />
                  </div>
                  : <MobileScreenshotWrapper key={src} src={src} width={240} height={496} />
              }
            )}
          </Carousel>
        </div>}

      {game.reviews && !!game.reviews.length &&
        <div className={'w-full flex justify-center py-10 md:py-15 px-4 md:px-12 xl:px-44'}>
          <GameReviews data={game.reviews} />
        </div>
      }

      <div className={'w-full flex justify-center py-10 md:py-15 px-4 md:px-12 xl:px-44'}>
        <SimilarGames games={game.similarGames} />
      </div>

    </>
  );
})

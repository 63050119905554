import React from 'react';
import ReviewItem from './reviewItem';
import Carousel from '../../uiKit/carousel';
import ArrowsOverControls from '../../uiKit/carousel/controls/arrowsOverControlsWithDotsIndicator';
import DotControls from '../../uiKit/carousel/controls/dotControls';
import Title from '../../uiKit/typography/title';
import { useBreakpoint } from '../../utils/hooks/useBreakpoint';
import { useTranslation } from 'react-i18next';

export default React.memo(function GameReviews({ data }) {
  const { t } = useTranslation('common');
  const { key: breakpointKey } = useBreakpoint();

  const isSmallScreen = ['xs', 'sm'].includes(breakpointKey);
  const isMediumScreen = ['md'].includes(breakpointKey);

  const slidesPerView = isSmallScreen ? 1 : isMediumScreen ? 3 : 5;

  const renderCarousel = () => (
    <Carousel
      slidesPerView={slidesPerView}
      loop={10000}
      className={`${slidesPerView === 1 ? '' : 'w-10/12 m-auto'} rounded-3xl h-max`}
      itemClassName={`items-start ${isSmallScreen ? '' : 'px-2'}`}
      applyControls={(props) => (
        <>
          <ArrowsOverControls {...props} className={'hidden md:flex items-center'}
                              itemClassName={'bottom-[-4rem]'} />
          <DotControls {...props} className={'md:hidden mt-2'} />
        </>
      )}>
      {data.map((item, index) => (
        <ReviewItem key={item.id ?? index} data={item} />
      ))}
    </Carousel>
  );

  const renderGrid = () => (
    <div className={
      `w-full grid grid-cols-[repeat(1,_minmax(0,_15rem))] 
        md:grid-cols-[repeat(auto-fit,_minmax(0,_15rem))] 
        md:grid-rows-1 md:auto-rows-[0] overflow-hidden 
        justify-center gap-y-8 gap-x-8 md:gap-y-0 mt-10 md:mt-24`
    }>
      {data.map((item, index) => (
        <ReviewItem key={item.id ?? index} data={item} />
      ))}
    </div>
  );

  return (
    <section className={'flex items-center flex-col w-full px-4'}>
      <div className={'flex items-center justify-center md:justify-between w-full'}>
        <Title>{t('titles.reviews')}</Title>
      </div>
      <div className={'w-full pt-10 pb-10 md:py-8 relative bg-white'}>
        {data.length > 4 ? renderCarousel() : renderGrid()}
      </div>
    </section>
  );
});

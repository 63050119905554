import React, { useState, useRef } from 'react';
import Paragraph from '../../uiKit/typography/paragraph';

export default React.memo(function ReviewItem({ data }) {

  return (
    <div className="px-2">
      <a title={data.title} href={data.link} target={'_blank'} className="block cursor-pointer">
        <img
          src={data.image}
          alt={data.title}
          className="object-cover w-full h-full rounded-3xl mx-auto max-h-[260px] max-w-[260px]"
          draggable={false}
        />
        <Paragraph className="mt-2 max-w-[260px]">
          {data.title}
        </Paragraph>
      </a>
    </div>
  );
});
